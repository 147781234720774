//Palette

//grey
$white: #ffffffff;
$grey_100: #fafbff;
$grey_200: #f8fafc;
$grey_300: #f7f8fb;
$grey_400: #eef2f7;
$grey_500: #c9ceda;
$grey_600: #98a1aa;
$grey_800: #6c757d;
$grey_900: #313a46;

//primary
$primary_100: #f4fafe;
$primary_200: #e8f6ff;
$primary_300: #e4f0ff;
$primary_400: #99d5fb;
$primary_600: #558ed5; //temp (wil be changed on #0097f4)
// $primary_600: #0097f4; //new design

//dark-blue
$dark-blue_100: #f1f3f9;
$dark-blue_500: #3c5eb2;
$dark-blue_600: #3858a6;
$dark-blue_700: #25438b;

//sea-blue
$sea-blue_100: #f1f9fc;
$sea-blue_400: #afe7f8;
$sea-blue_600: #39b3d7;

//violet
$violet_100: #f3f2fe;
$violet_300: #ebd8ff;
$violet_600: #504cf4;

//green
$green_100: #eefffa;
$green_600: #0acf97;

// red
$red_100: #fff4f6;
$red_600: #fa5c7c;
$red_700: #f9375e;

//yellow
$yellow_100: #fff9ed;
$yellow_300: #ffebd8;
$yellow_400: #fede83;
$yellow_600: #ffa800;

//box shadow colors
$primary-alpha_40: #0097f466;
$black-alpha_5: #0000000d;
$black-alpha_8: #00000014;
$black-alpha_10: #0000001a;
$dark-blue-alpha_16: #3858a629;
$dark-blue-alpha_20: #3858a633;

//for future: replace scss var-s with native css var-s

:root {
	//main colors
	--primary: #{$primary_600};
	--secondary: #{$grey_800};
	--light: #{$grey_400};
	--success: #{$green_600};
	--danger: #{$red_600};
	--info: #{$sea-blue_600};
	--dark: #{$grey_900};
	--warning: #{$yellow_600};
	--white: #{$white};

	// default colors
	--line-hover-bgc: #{$grey_300};
	--border-c: #{$grey_500};

	// other colors (need investigate)
	--offline: #{$grey_500};
	--close-icon-c: #{$grey_800};
	--image-box-bgc: #{$grey_300};
	--row-expand-bgc: #{$grey_300}; //for ngx datatable

	//text colors
	--text-offline-state: #{$grey_600};
	--text-light-grey: #{$grey_600};
	--text-dark: var(--dark);
	--text-secondary: var(--secondary);
	--text-primary: var(--primary);
	--text-light-primary: #{$primary_400};

	//table
	--table-header-bgc: #{$grey_400};
	--table-header-text-c: var(--text-secondary);
	--table-second-header-bgc: #{$grey_200};
	--table-row-text-c: var(--text-secondary);
	--table-row-border-c: #{$grey_400};
	--table-row-hover-bgc: var(--line-hover-bgc);
	--table-row-hover-text-c: var(--text-dark);
	--table-row-img-box-border-c: var(--border-c);
	--table-row-img-box-icon-c: var(--text-light-grey);
	--table-row-action-hover-bgc: #{$primary_200};
	--table-row-action-hover-text-c: var(--text-primary);
	--table-row-action-text-c: var(--text-light-primary);
	--table-row-action-selected-bgc: #{$primary_100};

	//element selection (table row, dropdown option)
	--item-selected-bgc: #{$primary_100};
	--item-selected-box-shadow: inset 0 0 0 2px #{$primary-alpha_40};
	--item-keyboard-select-bgc: #{$primary_100};
	--item-keyboard-select-hover-bgc: #{$primary_200};

	//tab menu components
	--tab-menu-border-c: var(--border-c);
	--tab-menu-text-c: var(--text-secondary);
	--tab-menu-link-bgc: transparent;
	--tab-menu-link-active-bgc: #{$white};

	// links
	--link-text-c: var(--text-primary);
	--link-hover-text-c: #{$dark-blue_600};

	//tooltip
	--tooltip-bgc: #{$white};
	--tooltip-border-c: #{$grey_900};
	--tooltip-text-c: var(--text-secondary);

	//accordion
	--accordion-header-bg: #{$grey_400};

	//scrollbar
	--scroll-bar-bg: #{$grey_100};
	--scroll-thumb-bg: #{$grey_500};

	//progress bar
	--progress-bgc: #{$grey_400};
	--progress-value-received-bgc: #{$sea-blue_600};
	--progress-value-shipped-bgc: #{$yellow_600};

	//label layouts

	//designer
	--designer-build-block-bgc: #{$white};
	--designer-build-block-hover-bgc: var(--line-hover-bgc);
	--designer-canvas-wrapper-bgc: #{$grey_400};

	//message (Toast, Inline message)
	--message-summary-text-c: var(--text-dark);
	--message-detail-text-c: var(--text-secondary);
	--message-close-icon-c: var(--close-icon-c);
	--message-success-bgc: #{$green_100};
	--message-success-border-c: var(--success);
	--message-success-icon-c: var(--success);
	--message-error-bgc: #{$red_100};
	--message-error-border-c: var(--danger);
	--message-error-icon-c: var(--danger);
	--message-warning-bgc: #{$yellow_100};
	--message-warning-border-c: var(--warning);
	--message-warning-icon-c: var(--warning);

	//site and page
	--content-page-box-shadow: 0px 4px 4px 0px #{$black-alpha_8};
	--site-body-bgc: #{$grey_100};

	//inputs
	--input-border-c: var(--border-c);
	--input-border-hover-c: var(--primary);
	--input-label-bgc: #{$grey_300};
	--input-label-text-c: var(--text-light-grey);
	--input-required-marker-c: var(--danger);
	--input-dropdown-option-hover-bgc: #{$grey_300};
	--input-dropdown-option-focus-bgc: #{$white};
	--input-readonly-border-c: var(--border-c);
	--input-readonly-text-c: var(--text-dark);
	--input-readonly-bgc: #{$grey_300};

	// badges
	--badge-grey-bgc: #{$grey_300};
	--badge-grey-text-c: #{$grey_600};
	--badge-danger-bgc: #{$red_100};
	--badge-danger-text-c: #{$red_600};
	--badge-violet-bgc: #{$violet_100};
	--badge-violet-text-c: #{$violet_600};
	--badge-warning-bgc: #{$yellow_100};
	--badge-warning-text-c: #{$yellow_600};
	--badge-info-bgc: #{$sea-blue_100};
	--badge-info-text-c: #{$sea-blue_600};
	--badge-success-bgc: #{$green_100};
	--badge-success-text-c: #{$green_600};
	--badge-dark-blue-bgc: #{$dark-blue_100};
	--badge-dark-blue-text-c: #{$dark-blue_600};
	--badge-primary-bgc: #{$primary_300};
	--badge-primary-text-c: #{$primary_600};
	--badge-zpl-layout-text-c: #{$grey_900};
	--badge-zpl-layout-bgc: #{$yellow_400};
	--badge-sld-layout-text-c: #{$grey_900};
	--badge-sld-layout-bgc: #{$sea-blue_400};

	//markers
	--marker-default-c: #{$grey_300};
	--marker-user-permission: #{$primary_600};
	--marker-order-transfer: #{$primary_300};
	--marker-order-purchase: #{$violet_300};
	--marker-order-sales: #{$yellow_300};
	--marker-primary: #{$primary_600};

	//buttons
	--button-primary-bgc: #{$primary_600};
	--button-primary-text-c: #{$white};
	--button-primary-border-c: #{$primary_600};
	--button-primary-hover-bgc: #{$violet_600};
	--button-primary-hover-text-c: #{$white};
	--button-primary-hover-border-c: #{$violet_600};
	--button-primary-active-bgc: #{$violet_600};
	--button-primary-active-border-c: #{$violet_600};

	--button-light-bgc: #{$grey_400};
	--button-light-text-c: var(--text-primary);
	--button-light-border-c: #{$grey_400};
	--button-light-hover-bgc: #{$grey_500};
	--button-light-hover-text-c: var(--text-dark);
	--button-light-hover-border-c: #{$grey_500};
	--button-light-active-bgc: #{$grey_500};
	--button-light-active-border-c: #{$grey_500};

	--button-danger-bgc: var(--danger);
	--button-danger-text-c: #{$white};
	--button-danger-border-c: var(--danger);
	--button-danger-hover-bgc: #{$red_700};
	--button-danger-hover-text-c: #{$white};
	--button-danger-hover-border-c: #{$red_700};
	--button-danger-active-bgc: #{$red_700};
	--button-danger-active-border-c: #{$red_700};

	--button-disabled-bgc: #{$grey_400};
	--button-disabled-text-c: var(--text-light-grey);
	--button-disabled-border-c: #{$grey_400};

	--icon-button-bgc: transparent;
	--icon-button-text-c: #{$primary_600};
	--icon-button-hover-bgc: #{$primary_100};

	//box-shadow
	--box-shadow-btn: 0 2px 4px 1px #{$dark-blue-alpha_20};
	--box-shadow-btn-focus: 0 0 0 3px #{$primary-alpha_40};
	--box-shadow-card: 0 4px 20px 0 #{$dark-blue-alpha_20};
	--box-shadow-sidebar: 0 0 30px 7px #{$dark-blue-alpha_16};
	--box-shadow-overlay-p: 0 2px 6px 0 #{$dark-blue-alpha_20};
	--box-shadow-confirm-msg: 0 0 30px 7px #{$dark-blue-alpha_16};
	--box-shadow-site-header: 0 4px 6px 0 #{$black-alpha_10};
	--box-shadow-delimiter: inset 0px 2px 4px 0px #{$black-alpha_10};
	--box-shadow-left-menu: 1px 2px 2px 0px #{$black-alpha_5};

	//left menu
	--left-menu-bgc: #{$white};
	--left-menu-item-text-c: var(--text-secondary);
	--left-menu-item-active-text-c: #{$dark-blue_600};
	--left-menu-item-active-bgc: #{$primary_200};
	--left-menu-item-hover-text-c: var(--text-secondary);
	--left-menu-item-hover-bgc: #{$grey_300};
	--left-menu-customer-block-text-c: var(--text-secondary);
	--left-menu-customer-block-bgc: #{$white};
}

// @media (prefers-color-scheme: dark) {
// 	:root {
// 		--left-menu-bgc: #3858a6;
// 		--left-menu-item-text-c: #fff;
// 		--left-menu-item-active-text-c: #3858a6;
// 		--left-menu-item-active-bgc: #fff;
// 		--left-menu-item-hover-text-c: #fff;
// 		--left-menu-item-hover-bgc: #3c5eb2;
// 		--left-menu-customer-block-text-c: #fff;
// 		--left-menu-customer-block-bgc: #25438b;
// 	}
// }
